import React from "react";
import { useEffect, useState } from "react";
import { usersByType } from "../callable-cloud-functions/cloudFunctions";

const toArray = (object) => {
  const ret = [];
  for (const key in object) {
    const item = {};
    const val = object[key];
    item[key] = val;
    ret.push(item);
  }
  return ret;
};

function UsersByType() {
  const [values, setValues] = useState([]);
  const [affiliationTypes, setAffilliationTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await usersByType();
      if (data?.affiliationTypes) {
        const arr = toArray(data.affiliationTypes);
        setAffilliationTypes(arr);
      }
      const dataArr = toArray(data);
      setValues(dataArr);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="w-full max-w-md mx-auto bg-[#E6F1FC] shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-wrap items-center justify-left text-xl font-extrabold text-[#1E596D] mb-4 p-4 border-b border-gray-300">
          Users By Type
        </div>
        <div className="w-full p-4">
          {values &&
            values.map((value, index) => {
              const label = Object.keys(value)[0];
              if (label !== "affiliationTypes" && label !== "createdAt") {
                if (label === "Affiliated") {
                  const affiliatedCount = value[label];
                  return (
                    <div key={index} className="mb-4">
                      <div className="w-full">
                        {label}
                        <span className="float-right">{affiliatedCount}</span>
                      </div>
                      {affiliationTypes &&
                        affiliationTypes.map((value, index) => {
                          const label = Object.keys(value)[0];
                          const count = value[label];
                          return (
                            <div
                              key={index}
                              className="w-full pl-4 text-gray-600 "
                            >
                              {label}
                              <span className="float-right">{count}</span>
                            </div>
                          );
                        })}
                    </div>
                  );
                } else {
                  const count = value[label];
                  return (
                    <div key={index} className="mb-4">
                      {label}
                      <span className="float-right">{count}</span>
                    </div>
                  );
                }
              }
            })}
        </div>
      </div>
    </>
  );
}

export default UsersByType;
