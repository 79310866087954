/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, useMemo } from "react";
import SettingsPassword from "./SettingsPassword";
import useModal from "../../../hooks/useModal";
import AddressForm from "../../address/AddressForm";
import SettingsMFA from "../../auth/multi-factor/SettingsMFA";
import Avatar from "./Avatar";
import toast from "react-hot-toast";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import AuthCode from "react-auth-code-input";
import { useForm } from "react-hook-form";


import { requireAuth, useAuth } from "../../../providers/auth";
import { updateUser } from "../../../data/user";
import { useAffiliations } from "../../../data/affiliation";
import { useRole } from "../../../hooks/useRole";
import { Type } from "../../../data/dictionary/type";
import Affiliations from "../details/parts/Affiliations";

import AutoSaveText from "../../custom-ui/input-fields/auto-save-field";
import AutoSaveSelectByLookup from "../../custom-ui/input-fields/auto-save-select-by-lookup";
import { useUser } from "../../../providers/database";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import AutoSaveCheckboxGroup from "../../custom-ui/input-fields/auto-save-checkbox-group";
import { useLookup } from "../../../data/lookup";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import { useResume } from "../../../data/seeker";
import { deleteUserAccount } from "../../../data/user";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { errorLogger } from "../../../providers/clientLogger";
import { firebaseApp } from "../../../providers/firebase";
// import DeleteMultiFactorEnrollment from "./DeleteMFATrigger";
// import DragAndDropVariantB from "../../custom-ui/drag-and-drop/DragAndDropFileSmall";
// import { upload } from "../../../storage/storage";
// import { writeResume } from "../../../data/seeker";
// import folderNames from "../../../storage/dictionary/folderNames";
// import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import SkillManagement from "./SkillManagement";
import SeekerFiles from "../../seeker/details/parts/SeekerFiles";
import AutoSaveWysiwyg from "../../custom-ui/input-fields/auto-save-wysiwyg";

import AddAffiliationModal from "../../seekerView/AddAffiliationModal";
import { updateAutoSave } from "../../../data/utility";
import DragAndDropFileAutoUpload from "../../custom-ui/drag-and-drop/DragAndDropFileAutoUpload"; import {
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth'
function SeekerSettingsGeneral(props) {
  const memberDateOptions = { year: "numeric", month: "short" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  const { register, handleSubmit, errors } = useForm();
  const auth = useAuth();
  const { getMultifactorSession, makePhoneAuthProvider, makeRecaptcha, user } = auth;
  const phoneAuthProvider = makePhoneAuthProvider();
  const [pending, setPending] = useState(false);
  const { data: userData } = useUser(auth?.user?.uid);
  const { data: seekerData, error: seekerError } = useResume(auth.user.uid);
  const { data: userAffiliationsData } = useAffiliations(auth?.user?.uid);
  const isInternalUser = auth?.user?.claims?.employerId === "000";
  const [avatar, setAvatar] = useState(auth?.user?.auth?.currentUser?.photoURL);
  const { data: model = [] } = useLookup("model");
  const { data: term = [] } = useLookup("term");
  const { data: classification = [] } = useLookup("classification")



  const [formAlert, setFormAlert] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const [multifactorSession, setMultifactorSession] = useState();
  const [verificationId, setVerificationId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [shouldMfaStart, setShouldMfaStart] = useState(false);
  const [mfaResolver, setMfaResolver] = useState();
  const [password, setPassword] = useState()
  const [counter, setCounter] = useState(5)
  const [startCounter, setStartCoutner] = useState(false)
  const [error, setError] = useState(false)


  const [type, setType] = useState("");
  const [mapSource, setMapSource] = useState(
    "https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed",
  );

  const [metadata, setMetadata] = useState({
    creationTime: "",
    lastSignInTime: "",
  });
  const { isShowing: showAvatarEditModal, toggle: toggleAvatarEditModal } =
    useModal();
  const { isShowing: showDeactivationModal, toggle: toggleDeactivationModal } =
    useModal();
  const { isShowing: showAffiliationsModal, toggle: toggleAffiliationsModal } =
    useModal();
  const { isShowing: showDeleteAcModal, toggle: toggleDeleteAccModal } =
    useModal();
  const { isShowing: showMFA, toggle: toggleMFAModal } = useModal()
  const { isShowing: showProceedDeleteAcc, toggle: toggleShowProceedDeleteAccl } = useModal()
  const { isShowing: showDeleteAcMailConfirmation, toggle: toggleshowDeleteAcMailConfirmation } = useModal()

  const today = new Date().toLocaleDateString();
  const lastTimeSignedInDate = new Date(
    auth.user.metadata.lastSignInTime,
  ).toLocaleDateString();

  const linkedInRegx = new RegExp(
    /(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/gm,
  );
  const getRole = useRole();
  const role = getRole(auth.user.claims.role, true);

  // updates image as soon as it's available
  useEffect(() => {
    if (avatar === undefined || avatar == null) {
      setAvatar("/images/default_user.png");
    }
    setAvatar(userData?.photoURL);
  }, [userData]);

  useEffect(() => {
    setType(Type[auth.user.claims.type]);
    if (auth.user?.address) {
      setMapSource(
        "https://maps.google.com/maps?q=" +
        auth.user.address.latitude +
        ", " +
        auth.user.address.longitude +
        "&z=15&output=embed",
      );
    }
    if (today === lastTimeSignedInDate) {
      setMetadata({
        creationTime: new Date(
          auth.user.metadata.creationTime,
        ).toLocaleDateString(undefined, memberDateOptions),
        lastSignInTime: new Date(
          auth.user.metadata.lastSignInTime,
        ).toLocaleTimeString(undefined, timeOptions),
      });
    } else {
      setMetadata({
        creationTime: new Date(
          auth?.user?.metadata.creationTime,
        ).toLocaleDateString(undefined, memberDateOptions),
        lastSignInTime: new Date(
          auth?.user?.metadata.lastSignInTime,
        ).toLocaleDateString(),
      });
    }
  }, [auth]);

  const inputFieldClasses = `
    py-1 mt-0.5
    w-full leading-8 
    bg-white
    outline-none 
    border border-gray-300 
  `;

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleDeactivation = async () => {
    await updateAutoSave(collectionNames.users, auth?.user?.uid, {
      status: "closed",
    }).then(() => {
      auth.signout();
    });
  };

  const handleDeleteAccount = () => {

    setStartCoutner(true)
    // auth.signout()
    return;
  }

  const handleAuthentication = async () => {


    const credentials = EmailAuthProvider.credential(userData?.email, password);
    console.log(credentials)

    reauthenticateWithCredential(user, credentials).then(() => { })
      .catch((error) => {

        console.log("PASSWORD ERROR---->", error)

        if (error.code == "auth/multi-factor-auth-required") {

          toggleMFAModal()
          toggleShowProceedDeleteAccl()
        } else if (error.code == "auth/too-many-requests") {
          setError("Too many request attempted. Try again later or reset password")
        }
        else {


          if (!password) {
            setError("Enter a password")
          } else {
            setError("Incorrect password")
          }

        }
      })



  }


  useEffect(() => {
    if (startCounter) {
      const interval = setInterval(() => {
        if (counter > 0) {
          setCounter(prevCount => prevCount - 1);

        } else {
          auth.signout();
          deleteUserAccount(userData?.email)
          toggleShowProceedDeleteAccl()
          clearInterval(interval);

        }
      }, 1000);

      return () => clearInterval(interval);
    }

  }, [counter, startCounter]);

  return (
    <div>
      <div className="flex flex-col justify-evenly ">
        <div className="flex justify-around w-full">
          <img
            alt="avatar"
            type="button"
            src={avatar ?? "/images/default_user.png"}
            className="w-48  max-h-56 object-cover rounded-xl   cursor-pointer mb-6"
            onClick={() => toggleAvatarEditModal()}
          />
          <h2 className=" font-semibold my-auto text-3xl text-instant-teams-blue-Main w-96 max-w-md ">
            {userData?.name}
          </h2>
          <div className="my-auto">
            <button
              onClick={() => toggleDeactivationModal()}
              className=" py-3 px-12 w-full text-white uppercase bg-instant-teams-red-Main hover:bg-instant-teams-red-L1 rounded-lg"
            >
              Deactivate My Account
            </button>
          </div>
        </div>
        <div className="flex justify-evenly space-x-10">
          <div className="w-1/2 md:w-1/2 lg:w-1/2 ">
            {/* 1st Column */}

            <GreenUnderlineHeading title="Basic Information" />

            <AutoSaveSelectByLookup
              value={auth.user.pronouns}
              group="pronoun"
              collection="Users"
              recId={auth.user.id}
              required={false}
              name="pronouns"
              display="Pronouns"
              placeholder="Select Pronouns"
              directions=""
            />
            <div className="mt-3">
              <AutoSaveText
                value={auth.user.name}
                collection="Users"
                recId={auth.user.id}
                required={true}
                name="name"
                display="Full Legal Name"
                placeholder="John Doe"
                directions="Name used on Driver's License or ID"
                readOnly={false}
              />
            </div>

            <AutoSaveText
              value={auth.user.preferredName}
              collection="Users"
              recId={auth.user.id}
              required={false}
              name="preferredName"
              display="Preferred Name"
              placeholder="Johnny Doe"
              directions="This will be your displayed name"
              readOnly={false}
            />

            <AutoSaveText
              value={auth.user.title}
              collection="Users"
              recId={auth.user.id}
              required={false}
              name="title"
              display="Title"
              placeholder=""
              directions=""
              readOnly={false}
            />
            <AutoSaveText
              value={auth.user.email}
              collection="Users"
              recId={auth.user.id}
              required={false}
              name="email"
              display="Email"
              placeholder=""
              directions=""
              readOnly={true}
            />
            <AutoSaveText
              value={auth.user.linkedin}
              collection="Users"
              recId={auth.user.id}
              required={false}
              name="linkedin"
              display="LinkedIn"
              placeholder="Full LinkedIn URL"
              directions="Full URL"
              readOnly={false}
            />
            {/* Currently only internal users can use Calendly links to schedule meetings. Marketplace can
          only track meeting updates for those users that are members of the instant teams organization  */}
            {isInternalUser && (
              <AutoSaveText
                value={auth?.user?.calendly}
                collection="Users"
                recId={auth.user.id}
                required={false}
                name="calendly"
                display="Calendly Link"
                placeholder="Enter your calendly link"
                directions="Full URL"
                readOnly={false}
                maxlength={150}
              />
            )}
            <div className=" text-instant-teams-blue-Main text-sm my-8">
              <h2>Military Affiliations</h2>
              <div className="flex flex-col place-items-center">
                <div className="flex mt-4 w-full font-black text-instant-teams-teal-D1">
                  <Affiliations
                    userId={auth.user.uid}
                    background={"lightBackground"}
                  />
                </div>

                <button
                  onClick={toggleAffiliationsModal}
                  className="w-1/3 mt-8 py-2 border border-instant-teams-blue-Main rounded-lg text-instant-teams-blue-Main hover:bg-instant-teams-blue-D1 hover:text-white"
                >
                  Add Affiliation
                </button>
              </div>
            </div>
            <div className="mb-2 mt-10 mr-4">
              <GreenUnderlineHeading title="My Work preferences" />

              <h2 className="my-2 text-instant-teams-blue-Main uppercase ">
                Work Setting
              </h2>
              <AutoSaveCheckboxGroup
                name="models"
                options={model}
                initialValues={seekerData?.models}
                collection={collectionNames.resume}
                id={auth.user.uid}
              />
              <h2 className="my-2 text-instant-teams-blue-Main uppercase ">
                Work Term
              </h2>
              <AutoSaveCheckboxGroup
                name="terms"
                options={term}
                initialValues={seekerData?.terms}
                collection={collectionNames.resume}
                id={auth.user.uid}
              />
              <h2 className="my-2 text-instant-teams-blue-Main uppercase ">
                Work Type
              </h2>
              <AutoSaveCheckboxGroup
                name="classifications"
                options={classification}
                initialValues={seekerData?.classifications}
                collection={collectionNames.resume}
                id={auth.user.uid}
              />
              <div className="mb-2 mt-11 mr-4">
                <GreenUnderlineHeading title="MY Resume & Files" />
                <div className="w-full grid grid-cols-1 gap-4">
                  {formAlert && (
                    <span className="mb-4 text-red-600">
                      {formAlert.message}
                    </span>
                  )}{" "}
                  <div className="text-center mb-2">
                    <DragAndDropFileAutoUpload />
                    <div className="relative flex justify-center"></div>
                  </div>
                  <SeekerFiles id={auth.user.uid} />
                </div>
              </div>
              <div className="w-full text-base mt-4">
                <AutoSaveWysiwyg
                  value={seekerData?.resume}
                  rows={5}
                  collection="Resumes"
                  recId={auth.user.uid}
                  required={false}
                  name="resume"
                  display="Resume"
                  placeholder={"Tell us about yourself..."}
                  directions=""
                  readOnly={false}
                />
              </div>
            </div>
          </div>
          {/* 2nd Column */}
          <div className="w-1/2 md:w-1/2 lg:w-1/2">
            {/* Password Begin */}
            {auth?.user?.claims?.type !== "A" && (
              <SettingsPassword onStatus={props.onStatus} />
            )}
            <GreenUnderlineHeading title="Two Factor Authentication(2fa)" />
            <div className="">
              <SettingsMFA onStatus={props.onStatus} />
            </div>
            <GreenUnderlineHeading title="Mailing Address" />
            {auth?.user?.address && (
              <div className="mx-auto center-items mb-4">
                <iframe
                  src={mapSource}
                  width="100%"
                  height="270"
                  frameBorder="0"
                  style={{ border: 0 }}
                ></iframe>
              </div>
            )}
            <AddressForm
              id={auth.user.id}
              address={auth.user.address}
              addressLabel="primary"
              onSubmit={updateUser}
              onDone={() => toast.success("Your address has been updated!")}
            />
            {/* Skill management configuration--start */}
            <div className="w-full mt-6 ">
              <SkillManagement userId={auth.user.id} />
            </div>
            {/* Skill management configuration---end */}
          </div>
        </div>
      </div>
      <ToastAlert />
      {showAffiliationsModal ? (
        <DialogWrapper
          title="NEW MILITARY AFFILIATION"
          size="w-1/3"
          onClose={toggleAffiliationsModal}
        >
          <AddAffiliationModal onDone={toggleAffiliationsModal} />
        </DialogWrapper>
      ) : null}
      {showAvatarEditModal ? (
        <Avatar toggleModal={toggleAvatarEditModal} />
      ) : null}
      {showDeactivationModal ? (
        <DialogWrapper
          title="Confirm Deactivation"
          onClose={toggleDeactivationModal}
        >
          <div className="text-center text-instant-teams-blue-Main mt-5">
            You are attempting to deactivate your Career Seeker account. Do you
            want to proceed?
          </div>
          <div className="flex justify-around space-x-10 mt-10 mb-4 mx-5">
            <button
              className="py-2 bg-instant-teams-blue-Main text-white w-full rounded-lg  hover:bg-instant-teams-blue-D1"
              onClick={handleDeactivation}
            >
              Yes
            </button>
            <button
              className="py-2 bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
              onClick={() => toggleDeactivationModal()}
            >
              No
            </button>
          </div>
        </DialogWrapper>
      ) : null}


      {/* delete account implementation starts here */}

      {showDeleteAcMailConfirmation &&
        (
          <DialogWrapper
            onClose={toggleshowDeleteAcMailConfirmation}
          >
            <div class="flex items-center text-gray-700 space-x-1 mb-4" >
              <i class="fa fa-trash text-3xl mr-2" aria-hidden="true"></i>
              <div class="flex items-center justify-center space-x-2 text-l" >
                <div class="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
              </div>
            </div>

            <div className=" text-red mt-1">
              <h5 className=" text-sm text-gray-600 ">
                Are you sure you want to permanently delete the account for
              </h5>
            </div>
            <div className=" text-red mt-1">
              <p className=" text-m text-gray-600  font-bold">
                {userData.email}
              </p>
            </div>
            <div className="flex items-center  space-x-1 mb-1 mt-7 ">
              <input
                type="checkbox"
                id="myCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="mr-2 outline-none focus:ring-0 rounded"

              />

              <p className="text-sm text-gray-500 ">I understand that I won't be able to recover my account.</p>
            </div>

            <div className="flex justify-around space-x-5 mt-10 mx=5 ">
              <button
                className={`py-2 px-2 w-full text-white uppercase ${!isChecked
                  ? 'bg-red-300 cursor-not-allowed' // Change color and cursor when disabled
                  : 'bg-instant-teams-red-Main hover:bg-instant-teams-red-L1'
                  } rounded-lg`}
                onClick={() => { toggleDeleteAccModal(); toggleshowDeleteAcMailConfirmation(); }}
                disabled={!isChecked}
              >
                Delete My Account
              </button>
              <button
                className="py-2  px-2  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
                onClick={() => toggleshowDeleteAcMailConfirmation()}
              >
                CANCEL
              </button>

            </div>
          </DialogWrapper>
        )

      }


      {showDeleteAcModal &&
        <DialogWrapper
          onClose={toggleDeleteAccModal}
        >
          <div class="flex items-center text-gray-700 justify-center space-x-2 mb-4" >
            <i class="fa fa-trash text-3xl " aria-hidden="true"></i>
          </div>
          <div class="flex items-center justify-center space-x-2 text-m" >
            <div class="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
          </div>
          <div className="text-center text-red mt-2 mb-2">
            <h5 className="text-instant-teams-red-Main text-l font-bold">
              Warning this is permanent and cannot be undone!
            </h5>
          </div>
          <div class="text-gray-500 text-sm mt-3">
            <p class="mt-2">* All of your applications will be immediately deleted.</p>
            <p class="mt-2">* Any offers that were released to you will be revoked.</p>
            <p class="mt-2">* You will no longer be able to log in to Instant Teams Marketplace.</p>
            <p class="mt-2">* Please note that if you are using this email address on 12M+ community websites, you will no longer be able to access the 12M+ community using this account.</p>
          </div>

          <div className="flex justify-around space-x-10 mt-5  mx-5">
            <button
              className=" py-2 px-9 w-full text-white uppercase bg-instant-teams-red-Main hover:bg-instant-teams-red-L1 rounded-lg"
              onClick={() => { toggleMFAModal(); toggleDeleteAccModal(); }}
            >
              Continue
            </button>
            <button
              className="py-2  px-9  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
              onClick={() => toggleDeleteAccModal()}
            >
              CANCEL
            </button>

          </div>
        </DialogWrapper>

      }

      {
        showMFA &&
        <DialogWrapper
          onClose={toggleMFAModal}
        >
          <div class="flex items-center text-gray-700 justify-center space-x-2 mb-4" >
            <i class="fa fa-trash text-3xl" aria-hidden="true"></i>
          </div>
          <div class="flex items-center justify-center space-x-2 mb-2 text-sm" >
            <div class="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
          </div>
          <div class="text-gray-500 text-sm mt-3 px-2 ">

            <p>Please enter the password</p>
          </div>
          <div>
            <div>
            </div>
            {/* <SettingsMFA onStatus={props.onStatus}/> */}
            <div className="px-2 mt-2">
              <input
                className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                name="pass"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setError(false)}
              />
              {error && <p className="mt-1 text-sm text-instant-teams-red-Main">{error}</p>}

              {/* {errors.pass && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.pass.message}
              </p>
            )} */}
            </div>
          </div>
          <div>
            {/* <MultiFactorEnrollment onStatus={props.onStatus}   callback={()=>{}}
              buttonVariant={"buttonVariant"}/> */}

          </div>


          <div className="flex justify-around space-x-10 mt-5 mx-5 mt-8">
            <button
              className=" py-2 px-9 w-full text-white uppercase bg-instant-teams-red-Main hover:bg-instant-teams-red-L1 rounded-lg"
              onClick={() => { handleAuthentication() }}
            >
              Continue
            </button>
            <button
              className="py-2  px-9  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
              onClick={() => toggleMFAModal()}
            >
              Cancel
            </button>

          </div>
        </DialogWrapper>

      }

      {
        showProceedDeleteAcc &&
        <DialogWrapper
          onClose={toggleShowProceedDeleteAccl}
        >
          <div class="flex items-center justify-center space-x-2 mb-4" >
            <i class="fa-thin fa-circle-check  text-3xl"></i>
          </div>
          <div className="text-center text-red  mb-3">
            <h5 className="mb-1">Done </h5>
            <h5 className="text-instant-teams-red-Main text-l font-bold">
              Your account will be shortly deleted
            </h5>
          </div>
          <div class="text-gray-500 text-center text-sm mt-3 text-center">
            <p class="mt-2">{`You will be redirected to career seeker portal within next  ${counter}  seconds.`}</p>
          </div>

          <div className="flex justify-around space-x-10 mt-5 mx-5">
            <button
              className=" py-2 px-9  bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black  rounded-lg"
              onClick={() => handleDeleteAccount()}
            >
              Proceed
            </button>
            {/* <button
              className="py-2  px-9  bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
              onClick={() => toggleShowProceedDeleteAccl()}
            >
              Cancel
            </button> */}

          </div>
        </DialogWrapper>

      }

      <div class="flex items-center justify-end space-x-2 mb-4 mt-10 cursor-pointer" onClick={() => { toggleshowDeleteAcMailConfirmation(); setIsChecked(false) }} >
        <i class="fa fa-trash text-gray-500" aria-hidden="true"></i>
        <p class="text-gray-500">Delete Account</p>
      </div>


    </div >
  );
}

export default requireAuth(SeekerSettingsGeneral);
