
import React, { useEffect, useState } from "react";
import CardContainer from "./CardContainer";
import { carrerSeekersDashboard } from "../../../callable-cloud-functions/cloudFunctions";
import LoadingSpinner from "./LoadingIndicator/LoadingSpinner";


const CareerSeekerCard = () => {

    const [resData, setResData] = useState()

    useEffect(() => {
        (async () => {
            const res = await carrerSeekersDashboard();
            setResData(res?.data)

        })();
    }, []);

    const totalUserCount = resData?.totalSeeker || 0
    const activeCount = resData?.active || 0
    const mau30Days = resData?.mau30Days || 0
    const newSeekerbylast30Days = resData?.newSeeker30Days || 0

    return (
        <CardContainer bgColor="#E6F1FC" cardTitle={"Career Seekers"}
            infoText={`
                    Total number of career seekers on marketplace (not including community members).
                    <br/>• <b>Active:</b> Seekers with account status as Active.
                    <br/>• <b>MAU:</b> Monthly Active Users for the last 30 days. Users who have logged
                    into the marketplace in the last month.
                    <br/>• <b>New Users:</b> New account created on marketplace.
                `}
        >
            {!resData ? (<div className="w-full h-[80%] items-center flex justify-center  ">
                <LoadingSpinner />
            </div>) :

                (<div className="w-full mt-4">
                    <p className="text-[#1E596D] text-[12px]">Total</p>
                    <h1 className="text-[#1E596D] font-bold text-3xl">{totalUserCount}</h1>
                    <div className="grid grid-cols-3 gap-4 w-full mt-2">
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Active</p>
                            <h1 className="text-[#1E596D] font-bold text-2xl">{activeCount}</h1>
                        </div>
                        <div className="ml-[-20px]">
                            <p className="text-[#1E596D] text-[12px] ">MAU (last 30 days)</p>
                            <h1 className="text-[#1E596D] font-bold text-2xl">{mau30Days}</h1>
                        </div>
                        <div className="ml-[-20px]">
                            <p className="text-[#1E596D] text-[12px]">New Users (last 30 days)</p>
                            <h1 className="text-[#1E596D] font-bold text-2xl">{newSeekerbylast30Days}</h1>
                        </div>
                    </div>
                </div>)}
        </CardContainer>
    )

}

export default CareerSeekerCard;
