//  this might be best used as an npm package to be shared between cloud functions and client apps.

type CollectionNames = {
  alerts: string;
  authOrigin: string;
  comReferredBy: string;
  emails: string;
  employers: string;
  employerStatus: string;
  enrollments: string;
  geoSearchSeeker: string;
  invitations: string;
  meetings: string;
  interviewQuestions: string;
  jobDescriptions: string;
  jobStatus: string;
  lookup: string;
  routes: string;
  users: string;
  userNotifications: string;
  marketing: string;
  notifications: string;
  chats: string;
  academyComponents: string;
  resetemails: string;
  settings: string;
  tags: string;
  resume: string;
  applications: string;
  applicationProgressSync: string;
  listings: string;
  videos: string;
  offers: string;
  employees: string;
  share: string;
  accelerators: string;
  rotatorImages: string;
  rotatorQuotes: string;
  declineDisagree: string;
  verifications: string;
  cannedSkills: string;
  tacTags: string;
  externalJobsStats: string;
};

type SubCollectionNames = {
  address: string;
  contacts: string;
  departments: string;
  files: string;
  notes: string;
  locations: string;
  marketing: string;
  messages: string;
  positions: string;
  skills: string;
  affiliations: string;
  preScreen: string;
  interviewQuestions: string;
  statusHistory: string;
  training: string;
};

export const collectionNames: CollectionNames = {
  alerts: "Alerts",
  authOrigin: "AuthOrigin",
  comReferredBy: "Comm_Referred_By",
  emails: "email",
  employers: "Employer",
  employerStatus: "EmployerStatus",
  enrollments: "Enrollments",
  geoSearchSeeker: "GeoSearchSeeker",
  invitations: "Invitations",
  meetings: "Meetings",
  interviewQuestions: "InterviewQuestions",
  jobDescriptions: "JobDescriptions",
  jobStatus: "JobStatus",
  lookup: "LookUp",
  routes: "Routes",
  users: "Users",
  userNotifications: "UserNotifications",
  marketing: "Marketing",
  notifications: "Notifications",
  chats: "Chats",
  resetemails: "ResetEmails",
  academyComponents: "AcademyAssessments",
  tags: "Tags",
  resume: "Resumes",
  settings: "Settings",
  applications: "Applications",
  applicationProgressSync: "ApplicationProgressSync",
  listings: "Listings",
  videos: "Videos",
  offers: "Offers",
  employees: "Employees",
  share: "Shares",
  accelerators: "AcademyAccelerators",
  rotatorImages: "RotatorImages",
  rotatorQuotes: "RotatorQuotes",
  declineDisagree: "DeclineDisagree",
  verifications: "Verifications",
  cannedSkills: "Canned_Skills",
  tacTags:"TAC_Tags",
  externalJobsStats:"ExternalJobsStats"
};

export const subCollectionNames: SubCollectionNames = {
  address: "Address",
  contacts: "Contacts",
  departments: "Departments",
  files: "Files",
  notes: "Notes",
  locations: "Locations",
  marketing: "MarketingViews",
  messages: "Messages",
  positions: "Positions",
  skills: "Skills",
  affiliations: "Affiliations",
  preScreen: "PreScreen",
  interviewQuestions: "InterviewQuestions",
  statusHistory: "StatusHistory",
  training: "Training",
};
