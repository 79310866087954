import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  runTransaction,
  onSnapshot,
  doc,
  collection,
  collectionGroup,
  getCountFromServer,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";
import { JobDescription } from "../dataTypes/JobDescription";
import { File } from "../dataTypes/Utilities";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

export function useAllListings(trigger: any) {
  return useQuery(
    ["Listings"],
    createQuery(() =>
      query(collection(db, collectionNames.listings), orderBy("title", "asc")),
    ),
    { enabled: !!trigger },
  );
}

export function useListing(id: string) {
  return useQuery(
    ["employer", { id }],
    createQuery(() => doc(db, collectionNames.listings, id)),
    { enabled: !!id },
  );
}


export function useAllListingsToInviteJob() {
  return useQuery(
    ["Listings"],
    async () => {
      const q = query(collection(db, collectionNames.listings), orderBy("title", "asc"));
      const querySnapshot = await getDocs(q);
      const listings = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      return listings;
    },
    {
      staleTime: 60000, // Example: Cache for 1 minute
    }
  );
}
