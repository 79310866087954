import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import InfoIcon from '../components/dashboard/AdminMetricDashboard/InfoTextWithIcon';
import LoadingSpinner from '../components/dashboard/AdminMetricDashboard/LoadingIndicator/LoadingSpinner';

const JobPieChart = ({
  extdata,
  chartFlag,
  daysSelected,
  infoText
}) => {
  const [chartSeries, setChartSeries] = useState([0, 0, 0]);
  const [selectedOption, setSelectedOption] = useState('Overall');
  const [totalValue, setTotalValue] = useState(0); // New state for total value 

  const key = `last${daysSelected}Days`;

  useEffect(() => {
    if (extdata && extdata?.data && chartFlag) {
      updateChartSeries('Overall'); // Trigger update when extdata and chartFlag are available
    }
  }, [extdata, chartFlag, daysSelected]);

  const updateChartSeries = (option) => {
    let jdata = extdata?.data?.[key];
    let iosTotal = 0,
      androidTotal = 0,
      webTotal = 0;

    if (chartFlag === 'Job' && jdata) {
      if (option === 'Overall') {
        iosTotal = (jdata?.bpo?.ios || 0) + (jdata?.dh?.ios || 0);
        androidTotal = (jdata?.bpo?.android || 0) + (jdata?.dh?.android || 0);
        webTotal = (jdata?.bpo?.web || 0) + (jdata?.dh?.web || 0);
      } else if (option === 'BPO') {
        iosTotal = jdata?.bpo?.ios || 0;
        androidTotal = jdata?.bpo?.android || 0;
        webTotal = jdata?.bpo?.web || 0;
      } else if (option === 'Direct Hire') {
        iosTotal = jdata?.dh?.ios || 0;
        androidTotal = jdata?.dh?.android || 0;
        webTotal = jdata?.dh?.web || 0;
      }
    } else if (chartFlag === 'User' && jdata) {
      iosTotal = jdata?.ios || 0;
      androidTotal = jdata?.android || 0;
      webTotal = jdata?.web || 0;
    }

    if (!isNaN(iosTotal) && !isNaN(androidTotal) && !isNaN(webTotal)) {
      const newSeries = [iosTotal, androidTotal, webTotal];
      setChartSeries(newSeries);

      // Calculate the total and set it
      const newTotal = newSeries.reduce((a, b) => a + b, 0);
      setTotalValue(newTotal); // Update total value
    }
  };

  const handleDropdownChange = (e) => {
    const selected = e.target.value;
    setSelectedOption(selected);
    updateChartSeries(selected);
  };



  const chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['iOS', 'Android', 'Web'],
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '10px',
            },
            total: {
              show: true,
              label: 'Total Interaction',
              fontSize: '14px',
              formatter: () => {
                return totalValue?.toLocaleString(); // Use derived totalValue state here
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#A3D4F7', '#113642', '#2E6675'],
    legend: {
      show: false,
    },
  };

  const legendItems = [
    { label: 'iOS', value: chartSeries[0]?.toLocaleString(), color: '#A3D4F7' },
    { label: 'Android', value: chartSeries[1]?.toLocaleString(), color: '#113642' },
    { label: 'Web', value: chartSeries[2]?.toLocaleString(), color: '#2E6675' },
  ];

  const LegendItem = ({ label, value, color }) => (
    <div className="flex items-center mb-4 text-xs">
      <div className="flex items-center justify-center text-white w-16 h-6 rounded" style={{ backgroundColor: color }}>
        <p className="absolute text-white">{label}</p>
      </div>
      <span className="text-xl font-bold ml-4 text-[#1E596D]">{value}</span>
    </div>
  );

  return (
    <div className="rounded-lg p-5 bg-white shadow-md w-full mx-auto">
      <div className="flex justify-between items-center mb-5">
        <div className="flex items-center relative">
          {chartFlag === 'Job' && (
            <h1 className="text-xl text-[#1E596D] font-bold">Job Interactions by Platform</h1>
          )}
          {chartFlag === 'User' && (
            <h1 className="text-xl text-[#1E596D] font-bold">Active Users by Platform</h1>
          )}
          <span className="text-[11px] ml-2 text-[#1E596D] mr-1">(Last {daysSelected} days)</span>
          <InfoIcon  tooltipText={infoText} />
        </div>
        {chartFlag === 'Job' && (
          <select
            className="px-2 py-1 border-2 border-[#1E596D] rounded text-sm w-28"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option>Overall</option>
            <option>Direct Hire</option>
            <option>BPO</option>
          </select>
        )}
      </div>

      <div className="flex items-center">

        {!extdata ? (<div className="w-full    rounded-lg  p-32 items-center flex justify-center  ">
          <LoadingSpinner />
        </div>) : (
          <>
            <div className="w-[64%]">
              {/* Force re-render with a unique key */}
              <Chart key={totalValue} options={chartOptions} series={chartSeries} type="donut" />
            </div>
            <div className="pl-5 flex flex-col">
              {legendItems.map((item, index) => (
                <LegendItem key={index} {...item} />
              ))}
            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default JobPieChart;
