import React from "react";
import CardContainer from "./CardContainer";
import { getSheerIdDataByDay } from "../../../callable-cloud-functions/cloudFunctions";
import { useState } from "react";
import { useEffect } from "react";
import LoadingSpinner from "./LoadingIndicator/LoadingSpinner";


const SheerIdCard = ({ daysSelected }) => {
    const [sheerIdData, setSheerIdData] = useState();
    const key = `last${daysSelected}Days`;
    useEffect(() => {
        async function fetchData() {
            const data = await getSheerIdDataByDay()
            setSheerIdData(data?.data)
        }
        fetchData()
    }, [])
    const totalCount = sheerIdData?.[key]?.approved + sheerIdData?.[key]?.rejected || 0
    const approvedCount = sheerIdData?.[key]?.approved || 0
    const rejectedCount = sheerIdData?.[key]?.rejected || 0
    return (
        <CardContainer  cardTitle={"Sheer ID verification"} extraHeight={true} viewDown={true} infoText={"SheerID verifications in the selected time period."} daysSelected={daysSelected}>
            {!sheerIdData ? (<div className="w-full h-[80%] items-center flex justify-center  ">
                <LoadingSpinner />
            </div>) : (
                <div className="w-full mt-4">
                    <p className="text-[#1E596D] text-[12px]">Total</p>
                    <h1 className="text-[#1E596D] font-bold text-3xl">{totalCount}</h1>
                    <div className="flex  mt-6">
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Approved</p>
                            <h1 className="text-[#1E596D] font-bold text-2xl">{approvedCount}</h1>
                        </div>
                        <div className="ml-8">
                            <p className="text-[#1E596D] text-[12px]">Rejected</p>
                            <h1 className="text-[#1E596D] font-bold text-2xl">{rejectedCount}</h1>
                        </div>

                    </div>
                </div>
            )}


        </CardContainer>
    )

}

export default SheerIdCard;
