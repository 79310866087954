import NewUserChart from "../../../charts/newUsers";
import UsersByType from "../../../charts/UsersByType";
import GlobalCounts from "../dataSets/counts";
import EnrollmentSyncFailures from "../dataSets/enrollmentSyncFailures";
import { useAllActiveAlerts } from "../../../data/alerts";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import AlertDisplay from "../../alerts/AlertDisplay";
import { useState, useEffect } from "react";
import EmployersCard from "../AdminMetricDashboard/EmployerCard";
import JobsCard from "../AdminMetricDashboard/JobsCard";
import CareerSeekerCard from "../AdminMetricDashboard/CareerSeekerCard";
// import InteractionChart from "../../../charts/InteractionsChart";
import SheerIdCard from "../AdminMetricDashboard/SheerIdCard";
import JobPieChart from "../../../charts/jobPieChart";
import { externalJobsPieChart } from "../../../callable-cloud-functions/cloudFunctions";
import { userPieChart } from "../../../callable-cloud-functions/cloudFunctions";

function SadminDashboard(props) {
  const [daysSelected, setDaysSelected] = useState(14)

  const { data: alerts } = useAllActiveAlerts();
  const { isShowing: isShowingAlerts, toggle: toggleAlerts } = useModal();
  let hideAlerts = sessionStorage.getItem("hideAlerts");

  const handleAlertClose = () => {
    sessionStorage.setItem("hideAlerts", "true");
    toggleAlerts();
  };
  function getFormattedDate() {
    const date = new Date();
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  }

 const [extJobsPieChartData, setExtJobsPieChartData] = useState();
  const [userPieChartData, setUserPieChartData] = useState();

  useEffect(() => {
    async function fetchData() {
      const data = await externalJobsPieChart()
      setExtJobsPieChartData(data)
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const data = await userPieChart()
      setUserPieChartData(data)
    }
    fetchData()
  }, [])
  return (
    <>
      <div className="w-[65%] mt-5 flex justify-between">
        <p className="text-xl text-[#1E596D] font-bold">{getFormattedDate()}</p>
        <div className="p-1 ">
          <select
            value={daysSelected}
            className="w-[150px] bg-white border py-2"
            onChange={(e) => { setDaysSelected(e.target.value) }}
          >
            <option className="py-2" value={3}>Last 3 Days</option>
            <option className="py-2" value={7}>Last 7 Days</option>
            <option className="py-2" value={14}>Last 14 Days</option>
            <option className="py-2" value={30}>Last 30 Days</option>
          </select>
        </div> </div>
      <div className="w-full grid mt-5 grid-cols-3 gap-3">
        <div className="w-full h-full col-span-2">
          <NewUserChart daysSelected={daysSelected} />
        </div>
        <div className="w-full">
          <SheerIdCard daysSelected={daysSelected} />
          {/* <UsersByType /> */}
        </div>
      </div>
      <div className="w-full grid mt-5 grid-cols-3 gap-4">
        {/* <div className="w-full col-span-2">
          <InteractionChart daysSelected={daysSelected} />
        </div> */}
        <div className="w-full">
          {/* <SheerIdCard /> */}
        </div>
      </div>
      <div className="w-full grid mt-5 grid-cols-2 gap-3">
        <JobPieChart extdata={extJobsPieChartData}
         chartFlag='Job'
          daysSelected={daysSelected} 
          infoText={"Total interactions (clicks) across various platforms in the selected time period."}     
          />
        <JobPieChart
         extdata={userPieChartData} 
         chartFlag='User' 
         daysSelected={daysSelected} 
         infoText={"Total active career seeker across various platforms in the selected time period."}
         />
      </div>
      <div className="w-full grid mt-8  grid-cols-3 gap-3">
        <EmployersCard />
        <JobsCard />
        <CareerSeekerCard />
      </div >
      <div className="w-full grid mt-8  grid-cols-3 gap-3">
          <UsersByType />
          <GlobalCounts />
      </div>
      {/* old code start */}
      < div className="grid grid-rows-2 grid-cols-4 gap-10" >
        {/* <div className="w-full col-span-2">
          <NewUserChart />
        </div>
        <div className="w-full">
          <UsersByType />
        </div>
        <div className="w-full">
          <GlobalCounts />
        </div>*/}
        {/* <div className="w-full col-span-2">
          <EnrollmentSyncFailures />
        </div> */}
        {
          !isShowingAlerts && !hideAlerts && alerts?.length > 0 && (
            <DialogWrapper
              title="System Alerts"
              onClose={handleAlertClose}
              size="w-1/3"
            >
              {/* @ts-ignore */}
              <AlertDisplay alerts={alerts} />
            </DialogWrapper>
          )
        }
      </div >
    </>
  );
}

export default SadminDashboard;
